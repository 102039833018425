import React from "react";
import { Container, Row, Col } from "../components/Grid";

import { getStatsByUserByTag } from "../services/quiznator";

export default class Stats extends React.Component {
  constructor() {
    super();
    this.state = {
      stats: { loading: true }
    };
  }

  componentDidMount() {
    getStatsByUserByTag(["ai-week1"]).then(stats => {
      this.setState({ stats: stats, loading: false });
    });
  }

  render() {
    return (
      <Container maxWidth={7} p={[5, 4]} offset={2}>
        <Row>
          <Col w={[8 / 12]} offset={[2 / 12]}>
            <div>
              {!this.state.stats.loading
                ? `${this.state.stats.count} out of ${this.state.stats.total} finished`
                : "Loading..."}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
